import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'
import Seo from '../components/plumbing/Head'
import CTA from '../components/Mailchimp'
import { Trails } from '../components/Trails'

const things = ['Art', 'Culture', 'Education', 'Education', 'Events', 'Experimental']

const DeveloperPage = () => (
  <Layout>
    <StaticImage
      src="../assets/images/developer.jpeg"
      alt="creator"
      style={{ marginTop: '8rem', height: '90%' }}
    />
    <div
      className="ten-central developer-heading"
      style={{
        position: 'relative',
        marginLeft: '9rem',
        bottom: '12rem',
        width: '80%',
      }}
    >
      <Trails
        text="Elysium powers the interactive design in the metaverse for experiences in "
        array={things}
      />
    </div>
    <Seo title="Developer Program" description="" />
    <section className="ten-central" style={{ marginBottom: '9rem', height: '100%' }}>
      <div className="ten-central">
        <p></p>
        <h1>Developer Program</h1>
        <p>
          ELYSIUM exponentially <b>accelerates XR production</b> with advanced real-time in situ collaborative
          tools. Prototype and build the spatialized interactive architecture for your XR experiences in the
          wild.
          <b>Save development time and costs</b> to enable you to focus on the creative design that will truly
          make your unique story a meaningful one.
        </p>
        <p>
          As we move towards a public launch, we are committed to building a sustainable{' '}
          <b>XR developer community</b>. Start building any XR experience with the power of ELYSIUM's no-code
          in situ AR tools. Apply to our developer program now to gain early access to our private beta and
          benefit from direct engagement with our engineers and other development teams within the ecosystem.
        </p>
        <p>
          <b>Join us</b> as we shape the future of XR through experiences for any/all platforms. Release dates
          and further details will be announced in <b>Q2 2022</b>. Sign up below for the latest updates.{' '}
        </p>
        <CTA />
      </div>
    </section>
  </Layout>
)

export default DeveloperPage
